<template>
  <div class="log-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="事件发送记录" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 操作类型 -->
        <ml-input
          prop="titleLike"
          placeholder="请输入消息标题"
          style="margin-bottom: 0; margin-right: 40px"
          label="消息标题:"
          v-model="searchData.titleLike"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="消息ID" prop="recordId" min-width="20%" /> -->
        <el-table-column align="center" label="触发条件" prop="recordId" min-width="15%">
          <template #default="scope">
            {{ [scope.row.province, scope.row.city, scope.row.area].join('') }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="消息标题" prop="title" min-width="15%" />
        <el-table-column align="center" label="发送对象" prop="userStatus" min-width="10%">
          <template #default="scope">
            {{ scope.row.userStatus === '0' ? '全部' : '指定用户' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="推送时间" prop="createTime" min-width="10%" />
        <el-table-column align="center" label="操作" priop="roleId" min-width="10%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-view"
              type="success"
              @click="clickView(scope.$index, scope.row)"
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 详情 -->
    <ml-dialog width="600px" ref="smesDetailsDialogRef" title="详情" :showSubmitBtn="false">
      <template #body>
        <div class="descriptions-list">
          <div v-for="item in descriptionsList" :key="item">
            <div>{{ item.label }}</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { tabberHeight, searchParams, getTabberData, replacePerCent } from '@/utils'
import { useStore } from 'vuex'

export default {
  name: 'EventSendRecords',
  setup() {
    const { dispatch } = useStore()
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      titleLike: ''
    })
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取列表
    const getLogTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.titleLike = replacePerCent(searchData.titleLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetEventSendRecords',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getLogTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    /* 结束 数据列表 **********************************/
    // 打开详情
    const smesDetailsDialogRef = ref()
    const smesDetailsDialogData = reactive({
      province: '',
      city: '',
      area: '',
      title: '',
      content: '',
      createTime: '',
      userStatus: 0,
      userVos: [],
      conditionVos: []
    })
    const setConditionVos = (conditionVos = []) => {
      const nConditionVos = []
      conditionVos.map(item => {
        const nameArr = item.conditions.map(v => v.name)
        nConditionVos.push(`${item.name}(${nameArr.join(',')})`)
      })
      return nConditionVos.join()
    }
    const descriptionsList = computed(() => {
      const {
        province,
        city,
        area,
        title,
        content,
        createTime,
        userStatus,
        userVos,
        conditionVos
      } = smesDetailsDialogData
      return [
        {
          label: '触发条件:',
          value: [province, city, area].join('')
        },
        {
          label: '天气类型',
          value: setConditionVos(conditionVos)
        },
        {
          label: '消息标题:',
          value: title
        },
        {
          label: '推送对象:',
          value:
            userStatus === '0' ? '全部' : `指定人员（${userVos.map(item => item.userName).join()}）`
        },
        {
          label: '推送时间:',
          value: createTime
        },
        {
          label: '消息内容:',
          value: content
        }
      ]
    })
    const clickView = async (index, row) => {
      const data = await dispatch('fetchGetEventSendRecord', row.recordId)
      if (data && data.code === 200) {
        Object.keys(smesDetailsDialogData).map(item => {
          smesDetailsDialogData[item] = data.data[item]
        })
      }
      smesDetailsDialogRef.value.showDialog = true
    }
    const smesDetailsDialogSubmit = () => {}

    onMounted(async () => {
      getLogTabberData()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      smesDetailsDialogRef,
      smesDetailsDialogSubmit,
      clickView,
      descriptionsList
    }
  }
}
</script>

<style lang="scss">
.log-manage {
  @extend %params-global;
}
.descriptions-list {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    min-height: 35px;
    margin-bottom: 20px;
    > div:last-child {
      flex: 1;
      margin-left: 20px;
    }
  }
}
</style>
